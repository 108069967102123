import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, Card, Table, InputGroup } from 'react-bootstrap';
import Loader from '../Loader';
import { useParams } from 'react-router-dom';
import axios from '../../api/axios';
import { Button, Modal } from 'antd';
import { twit } from '../Notificationpopout';
import * as GiIcon from 'react-icons/gi';
import * as RxIcons from 'react-icons/rx';
import * as TiIcons from 'react-icons/ti';
import * as MdIcons from 'react-icons/md';
import { fetchData } from '../../Utilities/apiRequests';
import { getNairobiTime, getNairobiTimeWithParam, getNumberOnly, getformattedDate } from '../../Utilities/utilities';
import { ImCross } from "react-icons/im";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { FcExpired } from "react-icons/fc";
import * as GrIcon from 'react-icons/gr';
import useGeneralState from '../../hooks/hooks/generalState';
import Pagination from '../Pagination';
import ImageCropper from '../ImageCropper';
import ImagePreview from '../ImagePreview';
import { fileUploadBaseUrl } from '../../Utilities/globals';


const EventSponsorForm = () => {
    const [sponsorName, setSponsorName] = useState('');
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [sponsors, setSponsors] = useState([]);
    const [showSponsorsUpdate, setShowSponsorsUpdate] = useState(false);
    const [selectedSponsor, setSelectedSponsor] = useState('');
    const [eventInfo, setEventInfo] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [showCropper, setShowCropper] = useState(false);
    const [cropperImage, setCropperImage] = useState('');
    const [sponsorImage, setSponsorImage] = useState('');
    const [sponsorImageLink, setSponsorImageLink] = useState(false);



    const { eid } = useParams();

    const sponsorformData = new FormData()
    sponsorformData.append("image", sponsorImage);

    const uploadSponsor = async (sid) => {
        const token = sessionStorage.getItem('token');

        try {



            const response = await axios.post(`${fileUploadBaseUrl}/api/events/sponsor/upload/${sid}`, sponsorformData, {
                headers: {
                    'content-type': 'multipart/form-data'
                    , Authorization: `Bearer ${token}`
                }

            });
            return Promise.resolve(response);
        } catch (error) {

            return Promise.reject(error);
        }



    }


    useEffect(() => {

        fetchData(`/api/events//sponsor/event/${eid}`, setLoading).then((response) => {

            setSponsors(response.data.sponsors)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event sponsors.')
        })

        fetchData(`/api/events/details/${eid}`, setLoading).then((response) => {
            setEventInfo(response.data.eventInfo)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event details.')
        })





    }, [refresh])

    const createSponsor = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            name: sponsorName,
            eid
        };
        const Url = '/api/events/sponsor/create'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                if (sponsorImage) {
                    const sponsorUpload = await uploadSponsor(response.data.createdSponsor.id)

                    if (sponsorUpload.status === 201) {
                        setLoading(false);
                        return Promise.resolve('Sponsor created successfully.')
                    }
                } else {
                    setLoading(false);
                    return Promise.resolve('Sponsor created successfully.')
                }

            }


        } catch (error) {
            setLoading(false);
            console.log("Sponsor creation Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while creating Sponsor.')

        }
    };

    const revokeActivateSponsor = async (setLoading, sid) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            sid
        };

        const Url = '/api/events/sponsor/status/update'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Sponsor status updated successfully.')
            }


        } catch (error) {
            setLoading(false);
            console.log("Sponsor update Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while updating sponsor status.')

        }
    };






    const handleSubmit = (e) => {
        e.preventDefault();

        if (!sponsorName || !sponsorImage || (sponsors.length >= 4)) {
            if (!sponsorName) {
                twit('info', 'Please provide the sponsor name.')
            }
            if (!sponsorImage) {
                twit('info', 'Please provide the sponsor image.')
            }

            if (sponsors.length) {
                twit('info', 'Maximum number of sponsors is 4.')
            }

        } else {
            createSponsor(setLoading).then((data) => {

                setSponsorName('');
                setSponsorImage('')
                setRefresh(!refresh)
                twit('success', data)

            }).catch((error) => {
                twit('info', error)
            })
        }



    };

    const updateSponsor = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const sid = selectedSponsor?.id
        const postData = {
            name: sponsorName ? sponsorName : selectedSponsor.name,
            sid
        };
        const Url = '/api/events/sponsor/update'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                if (sponsorImage) {
                    const sponsorUpload = await uploadSponsor(sid)

                    if (sponsorUpload.status === 201) {
                        setLoading(false);
                        return Promise.resolve('Sponsor updated successfully.')
                    }
                } else {
                    setLoading(false);
                    return Promise.resolve('Sponsor updated successfully.')
                }

            }


        } catch (error) {
            setLoading(false);
            console.log("Sponsor update Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while updating Sponsor.')

        }
    };




    const handleUpdateSubmit = (e) => {
        e.preventDefault();



        updateSponsor(setLoading).then((data) => {

            setSponsorName('');
            setSponsorImage('')
            setShowSponsorsUpdate(false)
            setRefresh(!refresh)
            twit('success', data)
        }).catch((error) => {
            twit('info', error)
        })

    };

    //pagination logic
    const postsPerPage = 5
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginatedSponsors = sponsors?.slice(indexOfFirstPost, indexOfLastPost)
    const paginate = (number) => {
        setCurrentPage(number);

    }


    if (loading) {
        return (<Loader />);
    } else {

        return (
            <Container>

                <div style={{ width: '100%', textAlign: 'center' }}>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>{eventInfo?.name}</p>
                </div>

                {showCropper && cropperImage && <Modal open={true} title={`Sponsor Image Formating (600 * 600)`} onCancel={() => {
                    setShowCropper(false)
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >

                        <Card>
                            <Card.Body >
                                <Row>
                                    <ImageCropper image={cropperImage} setImage={setCropperImage} setOpen={setShowCropper} setCropResultFile={setSponsorImage} windowWidth={'300px'} windowHeight={'300px'} aspectRatio={(600 / 600)} />
                                </Row>
                            </Card.Body>
                        </Card>

                    </div>


                </Modal>}

                {showSponsorsUpdate && <Modal open={true} title={'Sponsor Management'} onCancel={() => {

                    setShowSponsorsUpdate(false)
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >


                        <Row>
                            <Col>

                                <Form onSubmit={handleUpdateSubmit}>

                                    <Card>
                                        <Card.Body>
                                            <Row>
                                                <Col className='my-1' md={12}>
                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                        <div className='mx-2 FormLabels'>
                                                            <p style={{ margin: 0 }}></p>
                                                        </div>
                                                    </div>

                                                    <Form.Group className='my-2' controlId="sponsorName">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Name</p>
                                                            </div>
                                                            <Form.Control
                                                                type="text"
                                                                className=' input-box-events form-control-sm'
                                                                placeholder="Enter sponsor name"
                                                                value={(sponsorName != '') ? sponsorName : selectedSponsor.name}
                                                                onChange={(e) => {
                                                                    if (e.target.value !== '') {
                                                                        setSponsorName(e.target.value);
                                                                    } else {
                                                                        setSponsorName(null);
                                                                    }
                                                                }}
                                                                required
                                                            />
                                                        </div>


                                                    </Form.Group>

                                                </Col>
                                                <Col className='my-1' md={12}>
                                                    <Form.Group className='my-2' controlId="logoImage">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Logo</p>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: 'center', width: '100%' }}>
                                                                <div>
                                                                    <div className='my-1 ' style={{ width: '100%', }} >


                                                                        <ImagePreview selectedImage={sponsorImage} size={'600 * 600'} containerHeight='300px' containerWidth='300px' containerHeightMobile='200px' containerWidthMobile='200px' placeholderMessage={'This Will Appear On The Purchased Tickets.'} imagelink={sponsorImageLink} />

                                                                    </div>
                                                                </div>
                                                                <div style={{ width: '100%' }}>
                                                                    <Form.Control

                                                                        accept='.jpeg,.jpg,.png'
                                                                        name="logoImage"
                                                                        onChange={(e) => {


                                                                            setCropperImage(URL.createObjectURL(e.target.files[0]));
                                                                            setShowCropper(true)

                                                                        }}
                                                                        className=' input-box-events form-control-sm'

                                                                        type="file" />
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </Form.Group>
                                                </Col>


                                            </Row>
                                        </Card.Body>
                                    </Card>



                                    <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                        <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit'>
                                            <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Update Sponsor
                                        </Button>
                                    </div>
                                </Form>
                            </Col>

                        </Row>


                    </div>


                </Modal>}
                <Row>
                    <Col>
                        <p style={{ fontWeight: 'bold' }}> Sponsor Management</p>
                        <Form onSubmit={handleSubmit}>

                            <Card>
                                <Card.Body>
                                    <Row className="justify-content-center">
                                        <Col className='my-1' md={8}>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}></p>
                                                </div>
                                            </div>

                                            <Form.Group className='my-2' controlId="sponsorName">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Name</p>
                                                    </div>
                                                    <Form.Control
                                                        type="text"
                                                        maxLength={10}
                                                        className=' input-box-events form-control-sm'
                                                        placeholder="Enter sponsor name"
                                                        value={sponsorName}
                                                        onChange={(e) => {
                                                            setSponsorName(e.target.value)
                                                        }}
                                                        required
                                                    />
                                                </div>


                                            </Form.Group>

                                        </Col>






                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col className='my-1' md={8}>
                                            <Form.Group className='my-2' controlId="logoImage">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                    <div className='mx-2 FormLabels'>
                                                        <p style={{ margin: 0 }}>Logo</p>
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: 'center', width: '100%' }}>
                                                        <div>
                                                            <div className='my-1 ' style={{ width: '100%', }} >


                                                                <ImagePreview selectedImage={sponsorImage} size={'600 * 600'} containerHeight='300px' containerWidth='300px' containerHeightMobile='200px' containerWidthMobile='200px' placeholderMessage={'This Will Appear On The Purchased Tickets.'} />

                                                            </div>
                                                        </div>
                                                        <div style={{ width: '100%' }}>
                                                            <Form.Control

                                                                accept='.jpeg,.jpg,.png'
                                                                name="logoImage"
                                                                onChange={(e) => {


                                                                    setCropperImage(URL.createObjectURL(e.target.files[0]));
                                                                    setShowCropper(true)

                                                                }}
                                                                className=' input-box-events form-control-sm'

                                                                type="file" />
                                                        </div>
                                                    </div>

                                                </div>

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>



                            <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit'>
                                    <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Add Sponsor
                                </Button>
                            </div>
                        </Form>
                    </Col>

                </Row>
                <Row>
                    <p style={{ fontWeight: 'bold' }}> Event Sponsors </p>
                    <div className='table-responsive'>
                        {sponsors?.length > 0 ?
                            <Table striped hover style={{ cursor: "pointer" }}>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th>Operations</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedSponsors?.map((data, index) => (
                                        <tr key={index} onClick={() => { }}>
                                            <td>{((currentPage * 5) - postsPerPage) + (index + 1)}</td>
                                            <td>{data.name}</td>
                                            <td>{(data.active) ? <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />: <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> }</td>
                                            <td>


                                                <GrIcon.GrUploadOption className='mx-1' title='Update Sponsor' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                    setSponsorName('')
                                                    setSelectedSponsor(data)
                                                    setSponsorImageLink(data.url)
                                                    setShowSponsorsUpdate(true)

                                                }} />

                                                {
                                                    <>
                                                        {data.active ? (
                                                            <IoIosRemoveCircleOutline className='mx-1' title='Deactivate Sponsor' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                                revokeActivateSponsor(setLoading, data.id).then((data) => {
                                                                    setRefresh(!refresh)
                                                                    twit('success', data)
                                                                }).catch((error) => {
                                                                    twit('info', error)
                                                                })
                                                            }} />
                                                        ) : (
                                                            <IoIosAddCircleOutline className='mx-1' title='Activate Sponsor' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                                revokeActivateSponsor(setLoading, data.id).then((data) => {
                                                                    setRefresh(!refresh)
                                                                    twit('success', data)
                                                                }).catch((error) => {
                                                                    twit('info', error)
                                                                })
                                                            }} />
                                                        )}
                                                    </>
                                                }






                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            :

                            <p>No Sponsors added.</p>

                        }
                        {sponsors?.length > 0 && (
                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={sponsors?.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        )}

                    </div>
                </Row>
            </Container>
        );
    }

}

export default EventSponsorForm;
