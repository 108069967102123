import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row, Table } from 'react-bootstrap'
import { capitalizeFirstLetters, formatPrice, getNairobiTime, getNairobiTimeWithParam, getformattedDate } from '../../Utilities/utilities'
import Loader from '../Loader';
import { fetchData } from '../../Utilities/apiRequests';
import Pagination from '../Pagination';
import { costPerCredit } from '../../Utilities/globals';

const EventCollections = () => {


    const [starts, setStarts] = useState();
    const [ends, setEnds] = useState();
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [transactionData, setTransactionData] = useState([]);
    const [eventTransactions, setEventTransactions] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [events, setEvents] = useState([]);
    const [selectEventid, setSelectEventid] = useState('');
    const [selectEventData, setSelectEventData] = useState({});
    const [total, setTotal] = useState(0);

    useEffect(() => {
        fetchData(`/api/billing/accounts/collection/transactions`, setLoading).then((response) => {

            setTransactionData(response.data.transactions)
            
        }).catch((error) => {
            console.log('collections transactions loading error:', error.response?.data?.message ? error.response?.data?.message : 'error loading collection transactions.')
        })

        fetchData(`/api/events/list`, setLoading).then((response) => {

            setEvents(response.data.events)
            
        }).catch((error) => {
            console.log('collections transactions  events loading error:', error.response?.data?.message ? error.response?.data?.message : 'error loading collection transactions events.')
        })

    }, []);

    
    useEffect(() => {

        

        if(selectEventid){
            setEventTransactions(transactionData.filter((data)=>{
                return data.eid == selectEventid
            }))
        }else{
            setEventTransactions([])
        }
        

    }, [transactionData,selectEventid]);


    useEffect(() => {

        

        if(eventTransactions){
            if((!ends) && (!starts)){
                setTransactions(eventTransactions)
            }else if ((!ends)&&starts){
                setTransactions(eventTransactions.filter((data)=>{
                    return (data.tdate).split('T')[0] >= starts
                }))
            }else if((!starts)&& ends){
                setTransactions(eventTransactions.filter((data)=>{
                    return (data.tdate).split('T')[0] <= ends
                }))
            }else if(starts && ends){
                setTransactions(eventTransactions.filter((data)=>{
                    return ((data.tdate).split('T')[0] <= ends) && ((data.tdate).split('T')[0] >= starts)
                }))
            }
        }
        

    }, [eventTransactions,ends,starts]);

   
    

    useEffect(() => {

        let transactionTotal = 0

        if(transactions){
            for(const transaction of transactions){
                transactionTotal += transaction.credit
            }

            setTotal(transactionTotal)
        }
        

    }, [transactions]);



    useEffect(() => {

        

        if(selectEventid){

            setSelectEventData(events.filter((data)=> data?.eid == selectEventid)[0])
        }
        

    }, [selectEventid,events]);


    //pagination logic
    const postsPerPage = 5
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginatedTransactions = transactions?.slice(indexOfFirstPost, indexOfLastPost)
    const paginate = (number) => {
        setCurrentPage(number);

    }






    if (loading) {
        return (<Loader />);
    } else {

        return (
            <Container>
                <Row className='bills-summary-header'>
                    <Col md={6} >
                        <p className='mb-0' style={{ fontSize: '18px', fontWeight: 'bold' }}>Ticket Purchase Summary</p>
                        <p className='text-muted my-2'>Total Event Ticket Revenue Information</p>
                    </Col>
                </Row>

                <Row className='my-2' >
                <Col md={6} >
                        <p className='mb-0 text-muted' style={{ fontSize: '15px', fontWeight: 'bold' }}>Event</p>
                        <Row className='mt-2'>
                            <Col md={8}>
                            <Form.Group controlId="account">
                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                                <Form.Select
                                    //disabled
                                    name="event"
                                    onChange={(e) => {
                                        setSelectEventid(e.target.value)
                                    }}
                                    className='form-selector'>

                                    <option className='input-box' key={1} value={''}>{'Select Events'}</option>
                                    {events.map((data) => {
                                        return <option className='input-box' key={data?.eid} value={data?.eid}>{data?.name}</option>
                                    })}


                                </Form.Select>
                            </div>

                        </Form.Group>
                            </Col>
                        </Row>
                       

                    </Col>
                    <Col md={6} className='bill-summary-dates' >
                        <p className='mb-0 text-muted' style={{ fontSize: '15px', fontWeight: 'bold' }}>Transactions Period</p>
                        <Row>
                            <Col md={6} className='my-2'>
                                <Form.Group className='my-2' controlId="eventDate">
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                        <div className='mx-2 FormLabels'>
                                            <p style={{ margin: 0 }}>Start</p>
                                        </div>
                                        <Form.Control
                                            className=' input-box-events form-control-sm'
                                            type="date"
                                            name="startDate"
                                            value={starts}
                                            onChange={(e) => {
                                                setStarts(e.target.value)
                                            }}
                                            
                                        />
                                    </div>

                                </Form.Group>
                            </Col>
                            <Col md={6} className='my-2'>

                                <Form.Group className='my-2' controlId="endDate">

                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                        <div className='mx-2 FormLabels'>
                                            <p style={{ margin: 0 }}>End</p>
                                        </div>
                                        <Form.Control
                                            className=' input-box-events form-control-sm'
                                            type="date"
                                            name="endDate"
                                            value={ends}
                                            onChange={(e) => {
                                                setEnds(e.target.value)
                                            }}
                                            min={starts}
                                        />
                                    </div>

                                </Form.Group>
                            </Col>

                        </Row>
                    </Col>
                </Row>

                <Row  style={{ borderTop: '1px solid gray' }}>
                    <p className='mb-0 mt-2 text-muted' style={{ fontSize: '15px', fontWeight: 'bold' }}>{capitalizeFirstLetters(selectEventData?.name)} Transactions</p>
                    <div className='table-responsive'>
                        {paginatedTransactions?.length > 0 ?
                            <Table striped hover style={{ cursor: "pointer" }}>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>OrderNo.</th>
                                        <th>Zone</th>
                                        <th>Payment Type</th>
                                        <th>Tickets No.</th>
                                        <th style={{ textAlign: "right" }}>Rate</th>
                                        <th style={{ textAlign: "right" }}>Comission</th>
                                        <th style={{ textAlign: "right" }}>Amount</th>
                                        <th style={{ textAlign: "right" }}>Net Income</th>
                                        <th>Platform</th>
                                        <th>Date</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedTransactions?.map((data, index) => (
                                        <tr key={index} onClick={() => { }}>
                                            <td>{((currentPage * 5) - postsPerPage) + (index + 1)}</td>           
                                            <td>{data.PinnketTransaction.receiptNo}</td>
                                            <td>{data.EventZone.name}</td>
                                            <td>{data.paymentType}</td>
                                            <td >{data.ticketsBought}</td>
                                            <td style={{ textAlign: "right" }}>{formatPrice(data.ticketsUnitCost)}</td>
                                            <td style={{ textAlign: "right" }}>{formatPrice(data.commission)}</td>
                                            <td style={{ textAlign: "right" }}>{formatPrice(data.ticketsTotalCost)}</td>
                                            <td style={{ textAlign: "right" }}>{formatPrice(data.credit)}</td>
                                            <td >{data.TicketPurchasePlatform.name}</td>
                                            <td style={{fontSize:'10px',whiteSpace:'nowrap'}}>{getformattedDate(data.tdate)}</td>
                                            
                                            
                                            
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            
                            :

                            <p>{selectEventid?'No transactions.':'Select Event To View Transactions.'}</p>

                        }

                        {transactions?.length > 0 &&(
                            <Pagination
                            postsPerPage={postsPerPage}
                            totalPosts={transactions?.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                        ) }

                    </div>
                    
                </Row>
                <Row  style={{ borderTop: '1px solid gray' }}>
                    <Col className='mt-3' md={8} style={{textAlign:'end'}} >
                        <p className='mb-0' style={{ fontSize: '18px', fontWeight: 'bold' }}>Estimated Transactions Total:</p>
                    </Col>
                    <Col className='mt-3' md={4} style={{textAlign:'center'}} >
                        <p className='mb-0' style={{ fontSize: '20px', fontWeight: 'bold',borderBottom: '4px dotted #000' }}>{formatPrice(total.toFixed(2))}</p>
                    </Col>
                </Row>
                
            </Container>
        )
    }
}

export default EventCollections
