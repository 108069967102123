import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, Card, Table, InputGroup, ListGroup, Collapse } from 'react-bootstrap';
import Loader from '../Loader';
import { useParams } from 'react-router-dom';
import axios from '../../api/axios';
import { Button, Divider, Drawer, Modal } from 'antd';
import { twit } from '../Notificationpopout';
import * as GiIcon from 'react-icons/gi';
import * as RxIcons from 'react-icons/rx';
import * as TiIcons from 'react-icons/ti';
import * as MdIcons from 'react-icons/md';
import { fetchData } from '../../Utilities/apiRequests';
import { formatPrice, getNairobiTime, getNairobiTimeWithParam, getNumberOnly, getformattedDate } from '../../Utilities/utilities';
import { ImCross } from "react-icons/im";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { FcExpired } from "react-icons/fc";
import * as GrIcon from 'react-icons/gr';
import useGeneralState from '../../hooks/hooks/generalState';
import Pagination from '../Pagination';
import ImageCropper from '../ImageCropper';
import ImagePreview from '../ImagePreview';
import { fileUploadBaseUrl } from '../../Utilities/globals';
import BatchesPreview from '../BatchesSearch';
import ContainedLoader from '../ContainedLoader';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, Rectangle, PieChart, Pie, Cell, ResponsiveContainer, } from 'recharts';
import ColorCube from '../ColorCube';
import TicketsPreview from '../TicketSearch';



const EventBatchManagement = () => {

    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loadingBatches, setLoadingBatches] = useState(false);
    const [loadingBatchStatistics, setLoadingBatchStatistics] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [orders, setOrders] = useState([]);
    const [showOrderBatches, setShowOrderBatches] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState('');
    const [eventInfo, setEventInfo] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [orderBatches, setOrderBatches] = useState([]);
    const [selectedBatchId, setSelectedBatchId] = useState('');
    const [batchData, setBatchData] = useState();
    const [showBatchDetails, setShowBatchDetails] = useState(false);
    const [batchStatistics, setbatchStatistics] = useState({ sales: [], entry: [] });
    const [selectedOrderNumber, setSelectedOrderNumber] = useState('');
    const [selectedBatch, setSelectedBatch] = useState('');
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [onSmallScreen, setOnSmallScreen] = useState(false);
    const [viewTickets, setViewTickets] = useState(false);
    const [showBatchActivation, setShowBatchActivation] = useState(false);
    const [showBatchDeactivation, setShowBatchDeactivation] = useState(false);
    const [sellOnCheckin, setSellOnCheckin] = useState(false);
    const [sellAllonActivation, setSellAllonActivation] = useState(false);
    const [checkinOnBatchActivation, setCheckinOnBatchActivation] = useState(false);
    const [singleIndex, setSingleIndex] = useState();
    const [endIndex, setEndIndex] = useState();
    const [startIndex, setStartIndex] = useState();
    const [deactivateSingle, setDeactivateSingle] = useState(false);
    const [deactivateSeries, setDeactivateSeries] = useState(false);
    const [deactivateUnSold, setDeactivateUnSold] = useState(false);
    const [deactivateUnEntered, setDeactivateUnEntered] = useState(false);
    const [deactivateAll, setDeactivateAll] = useState(false);
    const [reactivateSingle, setReactivateSingle] = useState(false);
    const [reactivateSeries, setReactivateSeries] = useState(false);
    const [reactivateUnSold, setReactivateUnSold] = useState(false);
    const [reactivateUnEntered, setReactivateUnEntered] = useState(false);
    const [reactivateAll, setReactivateAll] = useState(false);
    const [showBatchReactivation, setShowBatchReactivation] = useState(false);
    const [sellers, setSellers] = useState([]);
    const [sid, setSid] = useState('');



    const { eid } = useParams();



    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);


        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);


    useEffect(() => {
        setOnSmallScreen((screenWidth < 770))
    }, [screenWidth]);




    useEffect(() => {

        fetchData(`/api/events/batchManagement/orders/${eid}`, setLoading).then((response) => {

            setOrders(response.data.orders)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event orders.')
        })



        fetchData(`/api/events/details/${eid}`, setLoading1).then((response) => {
            setEventInfo(response.data.eventInfo)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event details.')
        })


        fetchData(`/api/events/ticketSellers/event/${eid}`, setLoading2).then((response) => {

            setSellers(response.data.sellers)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading event ticket sellers.')
        })



    }, [refresh])




    useEffect(() => {



        if (selectedOrderId) {
            fetchData(`/api/events/batchManagement/batches/event/${selectedOrderId}/${eid}`, setLoadingBatches).then((response) => {
                setOrderBatches(response.data.batches)
            }).catch((error) => {
                twit('info', error.response?.data?.message ? error.response?.data?.message : 'Error loading Order batches.')
            })
        }



    }, [selectedOrderId, refresh])



    useEffect(() => {



        if (selectedBatchId) {
            fetchData(`/api/events/batchManagement/batches/statistics/${selectedBatchId}`, setLoadingBatchStatistics).then((response) => {
                setBatchData(response.data)
                
            }).catch((error) => {
                twit('info', error.response?.data?.message ? error.response?.data?.message : 'Error loading Batch Data.')
            })
        }



    }, [selectedBatchId])




    useEffect(() => {



        if (batchData) {
            const salesData = [

                {
                    "name": "Sold",
                    "value": batchData.sold,
                    "color": 'gold'
                },
                {
                    "name": "Not Sold",
                    "value": Number(batchData.total) - Number(batchData.sold),
                    "color": 'gray'
                },
            ];

            const entryData = [

                {
                    "name": "Entered",
                    "value": batchData.entered,
                    "color": 'green'
                },

                {
                    "name": "Not Entered",
                    "value": Number(batchData.total) - Number(batchData.entered),
                    "color": 'gray'
                }
            ];


            const statusData = [

                {
                    "name": "Active",
                    "value": batchData.active,
                    "color": '#89CFF0'
                },

                {
                    "name": "Inactive",
                    "value": Number(batchData.total) - Number(batchData.active),
                    "color": 'gray'
                }
            ];


            setbatchStatistics((prevBatchStatistics) => ({
                ...prevBatchStatistics,
                sales: salesData,
                entry: entryData,
                status: statusData
            }));

        }



    }, [batchData])


    const activateBatch = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {

            eid,
            bid: selectedBatch?.bid,
            sellOnCheckin,
            sellAllonActivation,
            checkinOnBatchActivation,
            sid
        };

        const Url = '/api/events/batch/activate/client'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Batch Activated Successfully.')
            }


        } catch (error) {
            setLoading(false);
            console.log("Batch Activation Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while activating batch.')

        }
    };





    const deactivateBatchTickets = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {

            eid,
            bid: selectedBatch?.bid,
            deactivateAll,
            deactivateUnEntered,
            deactivateUnSold,
            deactivateSeries,
            deactivateSingle,
            startIndex,
            endIndex,
            singleIndex

        };

        const Url = '/api/events/batchtickets/deactivate/client'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Batch Tickets Deactivated Successfully.')
            }


        } catch (error) {
            setLoading(false);
            console.log("Batch tickets deactivation Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while deactivating batch tickets.')

        }
    };




    const reactivateBatchTickets = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {

            eid,
            bid: selectedBatch?.bid,
            reactivateAll,
            reactivateUnEntered,
            reactivateUnSold,
            reactivateSeries,
            reactivateSingle,
            startIndex,
            endIndex,
            singleIndex

        };

        const Url = '/api/events/batchtickets/reactivate/client'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Batch Tickets Reactivated Successfully.')
            }


        } catch (error) {
            setLoading(false);
            console.log("Batch tickets reactivation Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while reactivating batch tickets.')

        }
    };



    const recallBatchTickets = async (bid) => {
        const token = sessionStorage.getItem('token');
        const postData = {

            eid,
            bid
        };

        const Url = '/api/events/batch/recall'

        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Batch Tickets Recalled Successfully.')
            }


        } catch (error) {
            setLoading(false);
            console.log("Batch tickets recall Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while recalling batch tickets.')

        }
    };

    const restoreBatchTickets = async (bid) => {
        const token = sessionStorage.getItem('token');
        const postData = {

            eid,
            bid
        };

        const Url = '/api/events/batch/restore'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                setLoading(false);
                return Promise.resolve('Batch Tickets Recalled Successfully.')
            }


        } catch (error) {
            setLoading(false);
            console.log("Batch tickets recall Error:", error)
            return Promise.reject(error.response?.data?.message ? error.response?.data?.message : 'An error occured while recalling batch tickets.')
        }
    };




    const handleViewBatchDetails = (batch) => {

        setShowBatchDetails(true)
        setSelectedBatchId(batch.bid)
        setSelectedBatch(batch)
    }

    const handleShowBatchActivationView = (batch) => {

        setShowBatchActivation(true)
        setSelectedBatchId(batch.bid)
        setSelectedBatch(batch)
    }

    const handleShowBatchDeactivationView = (batch) => {

        setShowBatchDeactivation(true)
        setSelectedBatchId(batch.bid)
        setSelectedBatch(batch)
    }

    const handleShowBatchReactivationView = (batch) => {

        setShowBatchReactivation(true)
        setSelectedBatchId(batch.bid)
        setSelectedBatch(batch)
    }

    const handleViewBatchDetailsClose = () => {

        setShowBatchDetails(false)
        setSelectedBatchId('')
        setSelectedBatch()
        setViewTickets(false)
    }

    const handleShowBatchActivationClose = () => {

        setShowBatchActivation(false)
        setSelectedBatchId('')
        setSelectedBatch()
        setSellAllonActivation(false)
        setSellOnCheckin(false)
        setCheckinOnBatchActivation(false)
        setSid('')
    }

    const handleShowBatchDeactivationClose = () => {

        setShowBatchDeactivation(false)
        setSelectedBatchId('')
        setSelectedBatch()
        setDeactivateSeries(false);
        setDeactivateSingle(false);
        setDeactivateUnSold(false);
        setDeactivateUnEntered(false);
        setDeactivateAll(false);
        setSingleIndex()
        setStartIndex()
        setEndIndex()
        setViewTickets(false)
    }

    const handleShowBatchReactivationClose = () => {

        setShowBatchReactivation(false)
        setSelectedBatchId('')
        setSelectedBatch()
        setReactivateSeries(false);
        setReactivateSingle(false);
        setReactivateUnSold(false);
        setReactivateUnEntered(false);
        setReactivateAll(false);
        setSingleIndex()
        setStartIndex()
        setEndIndex()
        setViewTickets(false)
    }


    const handleBatchActivation = (e) => {
        e.preventDefault();

        activateBatch(setLoading).then((data) => {
            twit('success', data)
            setRefresh(!refresh)
            handleShowBatchActivationClose()
        }).catch((error) => {
            twit('info', error)
        })

    };

    const handleBatchDeactivation = (e) => {
        e.preventDefault();

        deactivateBatchTickets(setLoading).then((data) => {
            twit('success', data)
            setRefresh(!refresh)
            handleShowBatchDeactivationClose()
        }).catch((error) => {
            twit('info', error)
        })

    };

    const handleBatchReactivation = (e) => {
        e.preventDefault();

        reactivateBatchTickets(setLoading).then((data) => {
            twit('success', data)
            setRefresh(!refresh)
            handleShowBatchReactivationClose()
        }).catch((error) => {
            twit('info', error)
        })

    };

    const tableStyles = {
        backgroundColor: '#f8f9fa', // Light gray background
        fontSize: '10px',
    };

    const headerStyles = {
        backgroundColor: '#343a40',
        color: '#fff',
    };

    const rowStyles = {
        height: '1px',
        padding: '0px',
        margin: '0px'
    };

    const rowCellStyles = {
        paddingTop: '2px',
        paddingBottom: '2px'
    }



    //pagination logic
    const postsPerPage = 5
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginatedOrders = orders?.slice(indexOfFirstPost, indexOfLastPost)
    const paginate = (number) => {
        setCurrentPage(number);

    }


    const COLORS = [
        '#0088FE',  // Blue (base)
        '#00C49F',  // Teal (base)
        '#FFBB28',  // Orange (base)
        '#FF8042',  // Dark Orange (base)
        '#33aaff',  // Lighter Blue (lightened from #0088FE)
        '#ffde5a',  // Yellow-Orange (analogous to #FFBB28)
        '#00ceff',  // Turquoise (triadic to #FF8042)

        '#A6CEE3',  // Light Blue (ColorBrewer2)
        '#1F77B4',  // Medium Blue (ColorBrewer2)
        '#FF7F0E',  // Bright Orange (Tableau)
        '#539E0B',  // Dark Green (Tableau)
        '#9467BD',  // Purple (accessible)

        // Shades of gray for emphasis (ensure sufficient contrast)
        // '#cccccc',
        // '#e0e0e0',
        // '#f2f2f2',

        // Additional contrasting colors (test for accessibility)
        '#f00',    // Red
        '#0f0',    // Green
        '#00f',    // Blue (darker)
        '#ffc0cb', // Light Pink
    ];


    const CustomTooltipRegistration = ({ active, payload, label }) => {




        const alldata = payload[0]
        const salesData = alldata?.payload
        const textColor = salesData?.fill



        if (active && payload && payload.length) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', backgroundColor: 'white', padding: '5px', border: '1px solid gray' }}>

                    <p className='mt-0 mb-1' style={{ color: `black`, fontWeight: 'bold', fontSize: '13px' }} >{salesData?.name}</p>
                    <hr className='mt-0 mb-1' style={{ width: '100%', color: 'gray' }} />
                    <p className='my-0' style={{ color: textColor, fontWeight: 'bolder', fontSize: '11px' }} ><span style={{ color: 'gray' }}> Total:</span> {salesData?.value}</p>

                </div>
            );
        }

        return null;
    };


    const handleRadioChange = (value) => {
        switch (value) {
            case 'sellOnCheckin':
                setSellOnCheckin(true);
                setSellAllonActivation(false);
                setCheckinOnBatchActivation(false);
                break;
            case 'sellAllonActivation':
                setSellOnCheckin(false);
                setSellAllonActivation(true);
                setCheckinOnBatchActivation(false);
                break;
            default:
                break;
        }
    };

    const handleOptionChangeDeactivation = (event) => {
        const value = event.target.value;

        switch (value) {
            case 'single':
                setDeactivateSeries(false);
                setDeactivateUnSold(false);
                setDeactivateUnEntered(false);
                setDeactivateAll(false);
                setDeactivateSingle(true);
                break;
            case 'series':
                setDeactivateSingle(false);
                setDeactivateUnSold(false);
                setDeactivateUnEntered(false);
                setDeactivateAll(false);
                setDeactivateSeries(true);
                break;
            case 'unsold':
                setDeactivateSingle(false);
                setDeactivateSeries(false);
                setDeactivateUnEntered(false);
                setDeactivateAll(false);
                setDeactivateUnSold(true);
                break;
            case 'unentered':
                setDeactivateSingle(false);
                setDeactivateSeries(false);
                setDeactivateUnSold(false);
                setDeactivateAll(false);
                setDeactivateUnEntered(true);
                break;
            case 'all':
                setDeactivateSingle(false);
                setDeactivateSeries(false);
                setDeactivateUnSold(false);
                setDeactivateUnEntered(false);
                setDeactivateAll(true);
                break;
            default:
                break;
        }
    };

    const handleOptionChangeReactivation = (event) => {
        const value = event.target.value;

        switch (value) {
            case 'single':
                setReactivateSeries(false);
                setReactivateUnSold(false);
                setReactivateUnEntered(false);
                setReactivateAll(false);
                setReactivateSingle(true);
                break;
            case 'series':
                setReactivateSingle(false);
                setReactivateUnSold(false);
                setReactivateUnEntered(false);
                setReactivateAll(false);
                setReactivateSeries(true);
                break;
            case 'unsold':
                setReactivateSingle(false);
                setReactivateSeries(false);
                setReactivateUnEntered(false);
                setReactivateAll(false);
                setReactivateUnSold(true);
                break;
            case 'unentered':
                setReactivateSingle(false);
                setReactivateSeries(false);
                setReactivateUnSold(false);
                setReactivateAll(false);
                setReactivateUnEntered(true);
                break;
            case 'all':
                setReactivateSingle(false);
                setReactivateSeries(false);
                setReactivateUnSold(false);
                setReactivateUnEntered(false);
                setReactivateAll(true);
                break;
            default:
                break;
        }
    };

    if (loading || loading1) {
        return (<Loader />);
    } else {

        return (
            <Container>

                <div style={{ width: '100%', textAlign: 'center' }}>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>{eventInfo?.name}</p>
                </div>


                {showOrderBatches && (
                    <>

                        <Drawer title="Order Batches" placement="right"
                            onClose={

                                () => {
                                    setShowOrderBatches(false)
                                }

                            } open={true}>
                            {
                                loadingBatches ?

                                    <ContainedLoader />

                                    :

                                    <BatchesPreview refresh={refresh} setRefresh={setRefresh} recallBatch={recallBatchTickets} restoreBatch={restoreBatchTickets} handleBatchReactivationView={handleShowBatchReactivationView} batches={orderBatches} batchId={selectedBatchId} handleBatchView={handleViewBatchDetails} orderNumber={selectedOrderNumber} handleBatchActivationView={handleShowBatchActivationView} handleBatchDeactivationView={handleShowBatchDeactivationView} />

                            }


                        </Drawer>

                    </>


                )}


                {showBatchDetails && <Modal open={true} title={'Batch Summary'} onCancel={() => {

                    handleViewBatchDetailsClose()
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >


                        {loadingBatchStatistics ?
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '40vh' }}>
                                <ContainedLoader />
                            </div>

                            :

                            <Row>

                                <Col className='mt-2 mb-2' md={12}>
                                    <Card style={{ height: '100%' }}>
                                        <Card.Body>

                                            <div className='my-2' style={{ width: '100%', backgroundColor: selectedBatch?.TagColor.name }}>

                                                <div style={{ width: '30%', textAlign: 'center', backgroundColor: `white`, marginLeft: '5%', padding: '5px' }}>
                                                    <Card.Title style={{ fontSize: '15px', margin: '0px', fontWeight: 'bold' }}>{selectedBatch?.DocumentTag.tagName}</Card.Title>
                                                </div>

                                            </div>

                                            <div style={{ width: '100%', textAlign: 'start' }}>
                                                <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Zone:</span> {selectedBatch?.EventZone.name}</p>
                                                <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }} >Status:</span> {!(selectedBatch?.isActive) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</p>
                                                <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Price:</span> UGX {formatPrice(selectedBatch?.EventZone.cost)}</p>
                                                <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Tickets In Batch:</span>  {formatPrice(batchData?.tickets?.length)}</p>
                                                {(selectedBatch?.isActive) ?
                                                    <>

                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Sell On Checkin:</span>  {!(selectedBatch?.sellOnCheckin) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Sell All on Activation: </span>  {!(selectedBatch?.sellAllonActivation) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold', color: (selectedBatch?.sellAllonActivation) ? 'black' : 'gray' }} >Checkin On Batch Activation:</span>  {!(selectedBatch?.checkinOnBatchActivation) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Activated By : </span>{selectedBatch?.doorChecker?.name} </p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Assigned To : </span>{batchData?.seller?.TicketSeller.name} </p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Activated On : </span>{getformattedDate(selectedBatch?.activatedAt)} </p>
                                                    </>
                                                    :
                                                    <></>
                                                }
                                            </div>



                                            <>

                                                <Divider orientation="left">Sales</Divider>

                                                {

                                                    <Row>
                                                        <Col md={6} style={{ minHeight: '100%' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                <ResponsiveContainer width='100%' height={120}>
                                                                    <PieChart >
                                                                        <Pie
                                                                            data={batchStatistics.sales}
                                                                            dataKey="value"
                                                                            nameKey="name"
                                                                            cx="50%%"
                                                                            cy="50%"
                                                                            innerRadius={onSmallScreen ? 5 : 25}
                                                                            outerRadius={onSmallScreen ? 20 : 40}
                                                                            fill="#03d3fc" // Default color for the entire Pie
                                                                            // label={renderCustomizedLabel}
                                                                            labelLine={false}
                                                                        >
                                                                            {batchStatistics.sales?.map((entry, index) => (
                                                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                                                            ))}
                                                                        </Pie>
                                                                        <Tooltip content={<CustomTooltipRegistration />} />
                                                                        <Legend />
                                                                    </PieChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                                                <Table striped bordered hover style={tableStyles}>
                                                                    <thead>
                                                                        <tr style={headerStyles}>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                                                            <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Category</th>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>Number </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {batchStatistics.sales?.map((item, index) => (
                                                                            <tr key={index} style={{ ...rowStyles }}>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                                                                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{item?.name}</td>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.value)}</td>
                                                                            </tr>
                                                                        ))}

                                                                    </tbody>
                                                                </Table>
                                                            </div>

                                                        </Col>
                                                    </Row>

                                                }


                                                <Divider orientation="left">Entry</Divider>

                                                {

                                                    <Row>
                                                        <Col md={6} style={{ minHeight: '100%' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                <ResponsiveContainer width='100%' height={120}>
                                                                    <PieChart >
                                                                        <Pie
                                                                            data={batchStatistics.entry}
                                                                            dataKey="value"
                                                                            nameKey="name"
                                                                            cx="50%%"
                                                                            cy="50%"
                                                                            innerRadius={onSmallScreen ? 5 : 25}
                                                                            outerRadius={onSmallScreen ? 20 : 40}
                                                                            fill="#03d3fc" // Default color for the entire Pie
                                                                            // label={renderCustomizedLabel}
                                                                            labelLine={false}
                                                                        >
                                                                            {batchStatistics.entry?.map((entry, index) => (
                                                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                                                            ))}
                                                                        </Pie>
                                                                        <Tooltip content={<CustomTooltipRegistration />} />
                                                                        <Legend />
                                                                    </PieChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                                                <Table striped bordered hover style={tableStyles}>
                                                                    <thead>
                                                                        <tr style={headerStyles}>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                                                            <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Category</th>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>Number </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {batchStatistics.entry?.map((item, index) => (
                                                                            <tr key={index} style={{ ...rowStyles }}>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                                                                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{item?.name}</td>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.value)}</td>
                                                                            </tr>
                                                                        ))}

                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>


                                                    </Row>

                                                }

                                                <Divider orientation="left">Status</Divider>

                                                {

                                                    <Row>
                                                        <Col md={6} style={{ minHeight: '100%' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                <ResponsiveContainer width='100%' height={120}>
                                                                    <PieChart >
                                                                        <Pie
                                                                            data={batchStatistics.status}
                                                                            dataKey="value"
                                                                            nameKey="name"
                                                                            cx="50%%"
                                                                            cy="50%"
                                                                            innerRadius={onSmallScreen ? 5 : 25}
                                                                            outerRadius={onSmallScreen ? 20 : 40}
                                                                            fill="#03d3fc" // Default color for the entire Pie
                                                                            // label={renderCustomizedLabel}
                                                                            labelLine={false}
                                                                        >
                                                                            {batchStatistics.status?.map((entry, index) => (
                                                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                                                            ))}
                                                                        </Pie>
                                                                        <Tooltip content={<CustomTooltipRegistration />} />
                                                                        <Legend />
                                                                    </PieChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>

                                                            <div className='mt-2 table-responsive ' style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                                                <Table striped bordered hover style={tableStyles}>
                                                                    <thead>
                                                                        <tr style={headerStyles}>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>No.</th>
                                                                            <th style={{ ...rowCellStyles, fontWeight: 'bold' }}>Category</th>
                                                                            <th style={{ textAlign: "center", ...rowCellStyles }}>Number </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {batchStatistics.status?.map((item, index) => (
                                                                            <tr key={index} style={{ ...rowStyles }}>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{index + 1}</td>
                                                                                <td style={{ fontWeight: 'bold', ...rowCellStyles }}>{item?.name}</td>
                                                                                <td style={{ textAlign: "center", ...rowCellStyles }}>{formatPrice(item?.value)}</td>
                                                                            </tr>
                                                                        ))}

                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>


                                                    </Row>

                                                }

                                                <Row>
                                                    <Col>
                                                        <div className='my-2' style={{ width: '100%', textAlign: 'center' }}>
                                                            <a style={{ margin: 0, fontWeight: 'bold', color: viewTickets ? 'red' : 'green' }} onClick={(e) => {
                                                                e.preventDefault()
                                                                if (viewTickets) {
                                                                    setViewTickets(false)
                                                                } else {
                                                                    setViewTickets(true)
                                                                }

                                                            }} >{viewTickets ? <MdIcons.MdOutlineExpandLess title='hide' style={{ height: "18px", width: "18px", color: 'gray' }} /> : <MdIcons.MdOutlineExpandMore title='Expand' style={{ height: "18px", width: "18px", color: 'gray' }} />}{viewTickets ? `Hide Batch Tickets` : `View Batch Tickets`}</a>
                                                        </div>
                                                        <Collapse in={viewTickets}>
                                                            <div>
                                                                <TicketsPreview tickets={batchData?.tickets} batchNumber={selectedBatch?.DocumentTag.tagName} />
                                                            </div>
                                                        </Collapse>
                                                    </Col>
                                                </Row>

                                            </>






                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>

                        }
                    </div>


                </Modal>}


                {showBatchActivation && <Modal open={true} title={'Batch Activation'} onCancel={() => {

                    handleShowBatchActivationClose()
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >


                        {loadingBatchStatistics ?
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '40vh' }}>
                                <ContainedLoader />
                            </div>

                            :

                            <Row>

                                <Col className='mt-2 mb-2' md={12}>
                                    <Card style={{ height: '100%' }}>
                                        <Card.Body>

                                            <div className='my-2' style={{ width: '100%', backgroundColor: selectedBatch?.TagColor.name }}>

                                                <div style={{ width: '30%', textAlign: 'center', backgroundColor: `white`, marginLeft: '5%', padding: '5px' }}>
                                                    <Card.Title style={{ fontSize: '15px', margin: '0px', fontWeight: 'bold' }}>{selectedBatch?.DocumentTag.tagName}</Card.Title>
                                                </div>

                                            </div>

                                            <div style={{ width: '100%', textAlign: 'start' }}>
                                                <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Zone:</span> {selectedBatch?.EventZone.name}</p>
                                                <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }} >Status:</span> {!(selectedBatch?.isActive) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</p>
                                                <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Price:</span> UGX {formatPrice(selectedBatch?.EventZone.cost)}</p>
                                                <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Tickets In Batch:</span>  {formatPrice(batchData?.tickets?.length)}</p>
                                                {(selectedBatch?.isActive) ?
                                                    <>

                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Sell On Checkin:</span>  {!(selectedBatch?.sellOnCheckin) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Sell All on Activation: </span>  {!(selectedBatch?.sellAllonActivation) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold', color: (selectedBatch?.sellAllonActivation) ? 'black' : 'gray' }} >Checkin On Batch Activation:</span>  {!(selectedBatch?.checkinOnBatchActivation) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Activated By : </span>{selectedBatch?.doorChecker?.name} </p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Assigned To : </span>{batchData?.seller?.TicketSeller.name} </p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Activated On : </span>{getformattedDate(selectedBatch?.activatedAt)} </p>
                                                    </>
                                                    :
                                                    <></>
                                                }
                                            </div>



                                            <>

                                                <Divider orientation="left">Activation</Divider>

                                                {

                                                    <Form onSubmit={handleBatchActivation}>
                                                        <Row>
                                                            <Form.Group className='my-2' controlId="TicketSeller">
                                                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                                    <div className='mx-2 my-2 FormLabels'>
                                                                        <p style={{ margin: 0 }}> Seller
                                                                            </p>
                                                                    </div>

                                                                    <Form.Select

                                                                        name="seller"
                                                                        required
                                                                        onChange={(e) => {
                                                                            setSid(e.target.value)
                                                                        }}
                                                                        className='form-selector'>
                                                                        <option className='input-box' key={1} value={''}>{'Select Seller'}</option>
                                                                        
                                                                        {sellers?.map((data) => {
                                                                            return <option className='input-box' key={data.id} value={data.id}>{data.name}</option>
                                                                        })}


                                                                    </Form.Select>
                                                                </div>



                                                            </Form.Group>
                                                            <Col md={12} style={{ minHeight: '100%' }}>
                                                                <div className='d-flex flex-row   w-100' style={{ alignItems: 'center' }}>
                                                                    <div className='mx-2 FormLabels'>
                                                                        <p style={{ margin: 0, fontWeight: 'bold' }}>Activation Mode </p>
                                                                    </div>
                                                                    <div className='d-flex flex-row w-100'>
                                                                        <Form.Check
                                                                            type="radio"
                                                                            label="Sell on Checkin"
                                                                            title='Each ticket in the batch  will be recorded as sold at checkin.'
                                                                            name="options"
                                                                            value="sellOnCheckin"
                                                                            checked={sellOnCheckin}
                                                                            onChange={(e) => handleRadioChange(e.target.value)}
                                                                            className="my-0 mx-2 input-box-events form-control-sm"
                                                                        />
                                                                        <Form.Check
                                                                            type="radio"
                                                                            label="Sell All On Activation"
                                                                            title='All tickets in the batch will be recorded as sold after batch activation.'
                                                                            name="options"
                                                                            value="sellAllonActivation"
                                                                            checked={sellAllonActivation}
                                                                            onChange={(e) => handleRadioChange(e.target.value)}
                                                                            className="my-0 mx-2 input-box-events form-control-sm"
                                                                        />

                                                                    </div>


                                                                </div>
                                                            </Col>
                                                            <Col md={12}>

                                                                {sellAllonActivation ?
                                                                    <div className='d-flex flex-column   w-100' >
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            label="Checkin On Activation"
                                                                            title='All tickets in the batch will also be checked in after batch activation.'
                                                                            name="checkinon"
                                                                            checked={checkinOnBatchActivation}
                                                                            onChange={

                                                                                () => {
                                                                                    setCheckinOnBatchActivation(!checkinOnBatchActivation)
                                                                                }
                                                                            }
                                                                            className="my-0 mx-2 input-box-events form-control-sm"
                                                                        />

                                                                    </div>
                                                                    :

                                                                    <></>

                                                                }

                                                            </Col>
                                                            <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit'  >
                                                                    <MdIcons.MdOutlineAdd className='mx-1' style={{ width: '15px', height: '15px' }} /> | Activate
                                                                </Button>
                                                            </div>

                                                        </Row>
                                                    </Form>

                                                }








                                            </>






                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>

                        }
                    </div>


                </Modal>}

                {showBatchDeactivation && <Modal open={true} title={'Batch Tickets Deactivation'} onCancel={() => {

                    handleShowBatchDeactivationClose()
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >


                        {loadingBatchStatistics ?
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '40vh' }}>
                                <ContainedLoader />
                            </div>

                            :

                            <Row>

                                <Col className='mt-2 mb-2' md={12}>
                                    <Card style={{ height: '100%' }}>
                                        <Card.Body>

                                            <div className='my-2' style={{ width: '100%', backgroundColor: selectedBatch?.TagColor.name }}>

                                                <div style={{ width: '30%', textAlign: 'center', backgroundColor: `white`, marginLeft: '5%', padding: '5px' }}>
                                                    <Card.Title style={{ fontSize: '15px', margin: '0px', fontWeight: 'bold' }}>{selectedBatch?.DocumentTag.tagName}</Card.Title>
                                                </div>

                                            </div>

                                            <div style={{ width: '100%', textAlign: 'start' }}>
                                                <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Zone:</span> {selectedBatch?.EventZone.name}</p>
                                                <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }} >Status:</span> {!(selectedBatch?.isActive) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</p>
                                                <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Price:</span> UGX {formatPrice(selectedBatch?.EventZone.cost)}</p>
                                                <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Tickets In Batch:</span>  {formatPrice(batchData?.tickets?.length)}</p>
                                                {(selectedBatch?.isActive) ?
                                                    <>

                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Sell On Checkin:</span>  {!(selectedBatch?.sellOnCheckin) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Sell All on Activation: </span>  {!(selectedBatch?.sellAllonActivation) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold', color: (selectedBatch?.sellAllonActivation) ? 'black' : 'gray' }} >Checkin On Batch Activation:</span>  {!(selectedBatch?.checkinOnBatchActivation) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Activated By : </span>{selectedBatch?.doorChecker?.name} </p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Assigned To : </span>{batchData?.seller?.TicketSeller.name} </p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Activated On : </span>{getformattedDate(selectedBatch?.activatedAt)} </p>
                                                    </>
                                                    :
                                                    <></>
                                                }
                                            </div>


                                            <Row>
                                                <Col>
                                                    <div className='my-2' style={{ width: '100%', textAlign: 'center' }}>
                                                        <a style={{ margin: 0, fontWeight: 'bold', color: viewTickets ? 'red' : 'green' }} onClick={(e) => {
                                                            e.preventDefault()
                                                            if (viewTickets) {
                                                                setViewTickets(false)
                                                            } else {
                                                                setViewTickets(true)
                                                            }

                                                        }} >{viewTickets ? <MdIcons.MdOutlineExpandLess title='hide' style={{ height: "18px", width: "18px", color: 'gray' }} /> : <MdIcons.MdOutlineExpandMore title='Expand' style={{ height: "18px", width: "18px", color: 'gray' }} />}{viewTickets ? `Hide Batch Tickets` : `View Batch Tickets`}</a>
                                                    </div>
                                                    <Collapse in={viewTickets}>
                                                        <div>
                                                            <TicketsPreview tickets={batchData?.tickets} batchNumber={selectedBatch?.DocumentTag.tagName} />
                                                        </div>
                                                    </Collapse>
                                                </Col>
                                            </Row>


                                            <>

                                                <Divider orientation="left">Deactivation</Divider>

                                                {

                                                    <Form onSubmit={handleBatchDeactivation}>
                                                        <Form.Group>
                                                            <Form.Label style={{ fontWeight: 'bold' }}>Ticket Deactivation Options</Form.Label>
                                                            <Form.Check
                                                                type="radio"
                                                                label="Single"
                                                                title='Deactivate Single Ticket'
                                                                value="single"
                                                                name="activationOption"
                                                                id="singleOption"
                                                                onChange={handleOptionChangeDeactivation}

                                                            />
                                                            {deactivateSingle && (
                                                                <Form.Control className=' input-box-events form-control-sm my-2' type="number" placeholder="Single Index" min={1} value={singleIndex} onChange={(e) => setSingleIndex(e.target.value)} />
                                                            )}

                                                            <Form.Check
                                                                type="radio"
                                                                label="Series"
                                                                title='Deactivate A Range of Tickets'
                                                                value="series"
                                                                name="activationOption"
                                                                id="seriesOption"
                                                                onChange={handleOptionChangeDeactivation}

                                                            />
                                                            {deactivateSeries && (
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <Form.Control min={1} className=' input-box-events form-control-sm mx-2 my-2' type="number" placeholder="Start Index" value={startIndex} onChange={(e) => setStartIndex(e.target.value)} />
                                                                    <Form.Control min={1} className=' input-box-events form-control-sm mx-2 my-2' type="number" placeholder="End Index" value={endIndex} onChange={(e) => setEndIndex(e.target.value)} />
                                                                </div>
                                                            )}


                                                            <Form.Check
                                                                type="radio"
                                                                label="Unsold"
                                                                value="unsold"
                                                                title='Deactivate Unsold Tickets'
                                                                name="activationOption"
                                                                id="unsoldOption"
                                                                onChange={handleOptionChangeDeactivation}
                                                            />

                                                            <Form.Check
                                                                type="radio"
                                                                label="Unentered"
                                                                value="unentered"
                                                                title='Deactivate Unentered Tickets'
                                                                name="activationOption"
                                                                id="unenteredOption"
                                                                onChange={handleOptionChangeDeactivation}
                                                            />

                                                            <Form.Check
                                                                type="radio"
                                                                label="All"
                                                                value="all"
                                                                title='Deactivate All Tickets'
                                                                name="activationOption"
                                                                id="allOption"
                                                                onChange={handleOptionChangeDeactivation}
                                                            />

                                                        </Form.Group>

                                                        <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                                            <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit'  >
                                                                <MdIcons.MdOutlineReduceCapacity className='mx-1' style={{ width: '15px', height: '15px' }} /> | Deactivate
                                                            </Button>
                                                        </div>

                                                    </Form>

                                                }








                                            </>






                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>

                        }
                    </div>


                </Modal>}

                {showBatchReactivation && <Modal open={true} title={'Batch Tickets Reactivation'} onCancel={() => {

                    handleShowBatchReactivationClose()
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >


                        {loadingBatchStatistics ?
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '40vh' }}>
                                <ContainedLoader />
                            </div>

                            :

                            <Row>

                                <Col className='mt-2 mb-2' md={12}>
                                    <Card style={{ height: '100%' }}>
                                        <Card.Body>

                                            <div className='my-2' style={{ width: '100%', backgroundColor: selectedBatch?.TagColor.name }}>

                                                <div style={{ width: '30%', textAlign: 'center', backgroundColor: `white`, marginLeft: '5%', padding: '5px' }}>
                                                    <Card.Title style={{ fontSize: '15px', margin: '0px', fontWeight: 'bold' }}>{selectedBatch?.DocumentTag.tagName}</Card.Title>
                                                </div>

                                            </div>

                                            <div style={{ width: '100%', textAlign: 'start' }}>
                                                <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Zone:</span> {selectedBatch?.EventZone.name}</p>
                                                <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold' }} >Status:</span> {!(selectedBatch?.isActive) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</p>
                                                <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Price:</span> UGX {formatPrice(selectedBatch?.EventZone.cost)}</p>
                                                <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Tickets In Batch:</span>  {formatPrice(batchData?.tickets?.length)}</p>
                                                {(selectedBatch?.isActive) ?
                                                    <>

                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Sell On Checkin:</span>  {!(selectedBatch?.sellOnCheckin) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Sell All on Activation: </span>  {!(selectedBatch?.sellAllonActivation) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold', color: (selectedBatch?.sellAllonActivation) ? 'black' : 'gray' }} >Checkin On Batch Activation:</span>  {!(selectedBatch?.checkinOnBatchActivation) ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Activated By : </span>{selectedBatch?.doorChecker?.name} </p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Assigned To : </span>{batchData?.seller?.TicketSeller.name} </p>
                                                        <p style={{ margin: 0, }}><span style={{ fontWeight: 'bold' }} >Activated On : </span>{getformattedDate(selectedBatch?.activatedAt)} </p>
                                                    </>
                                                    :
                                                    <></>
                                                }
                                            </div>


                                            <Row>
                                                <Col>
                                                    <div className='my-2' style={{ width: '100%', textAlign: 'center' }}>
                                                        <a style={{ margin: 0, fontWeight: 'bold', color: viewTickets ? 'red' : 'green' }} onClick={(e) => {
                                                            e.preventDefault()
                                                            if (viewTickets) {
                                                                setViewTickets(false)
                                                            } else {
                                                                setViewTickets(true)
                                                            }

                                                        }} >{viewTickets ? <MdIcons.MdOutlineExpandLess title='hide' style={{ height: "18px", width: "18px", color: 'gray' }} /> : <MdIcons.MdOutlineExpandMore title='Expand' style={{ height: "18px", width: "18px", color: 'gray' }} />}{viewTickets ? `Hide Batch Tickets` : `View Batch Tickets`}</a>
                                                    </div>
                                                    <Collapse in={viewTickets}>
                                                        <div>
                                                            <TicketsPreview tickets={batchData?.tickets} batchNumber={selectedBatch?.DocumentTag.tagName} />
                                                        </div>
                                                    </Collapse>
                                                </Col>
                                            </Row>


                                            <>

                                                <Divider orientation="left">Reactivation</Divider>

                                                {

                                                    <Form onSubmit={handleBatchReactivation}>
                                                        <Form.Group>
                                                            <Form.Label style={{ fontWeight: 'bold' }}>Ticket Reactivation Options</Form.Label>
                                                            <Form.Check
                                                                type="radio"
                                                                label="Single"
                                                                value="single"
                                                                title='Reactivate A Single Ticket'
                                                                name="activationOption"
                                                                id="singleOption"
                                                                onChange={handleOptionChangeReactivation}

                                                            />
                                                            {reactivateSingle && (
                                                                <Form.Control className=' input-box-events form-control-sm my-2' type="number" placeholder="Single Index" min={1} value={singleIndex} onChange={(e) => setSingleIndex(e.target.value)} />
                                                            )}

                                                            <Form.Check
                                                                type="radio"
                                                                label="Series"
                                                                value="series"
                                                                title='Reactivate A Range of Tickets'
                                                                name="activationOption"
                                                                id="seriesOption"
                                                                onChange={handleOptionChangeReactivation}

                                                            />
                                                            {reactivateSeries && (
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <Form.Control min={1} className=' input-box-events form-control-sm mx-2 my-2' type="number" placeholder="Start Index" value={startIndex} onChange={(e) => setStartIndex(e.target.value)} />
                                                                    <Form.Control min={1} className=' input-box-events form-control-sm mx-2 my-2' type="number" placeholder="End Index" value={endIndex} onChange={(e) => setEndIndex(e.target.value)} />
                                                                </div>
                                                            )}


                                                            <Form.Check
                                                                type="radio"
                                                                label="Unsold"
                                                                value="unsold"
                                                                title='Reactivate Unsold Tickets'
                                                                name="activationOption"
                                                                id="unsoldOption"
                                                                onChange={handleOptionChangeReactivation}
                                                            />

                                                            <Form.Check
                                                                type="radio"
                                                                label="Unentered"
                                                                value="unentered"
                                                                title='Reactivate Unentered Tickets'
                                                                name="activationOption"
                                                                id="unenteredOption"
                                                                onChange={handleOptionChangeReactivation}
                                                            />

                                                            <Form.Check
                                                                type="radio"
                                                                label="All"
                                                                value="all"
                                                                title='Reactivate All Tickets'
                                                                name="activationOption"
                                                                id="allOption"
                                                                onChange={handleOptionChangeReactivation}
                                                            />

                                                        </Form.Group>

                                                        <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                                            <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit'  >
                                                                <MdIcons.MdAdd className='mx-1' style={{ width: '15px', height: '15px' }} /> | Reactivate
                                                            </Button>
                                                        </div>

                                                    </Form>

                                                }








                                            </>






                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>

                        }
                    </div>


                </Modal>}

                <Row>
                    <p style={{ fontWeight: "bold" }}> Tag Orders </p>
                    <div className="table-responsive">
                        {orders.length > 0 ? (
                            <Table striped hover style={{ cursor: "pointer" }}>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Order No.</th>
                                        <th>Tag Type</th>
                                        <th>Status</th>
                                        <th>Batches</th>
                                        <th>Placed</th>
                                        <th>Operations</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedOrders?.map((data, index) => (
                                        <tr key={index} onClick={() => { }}>
                                            <td>{((currentPage * 5) - postsPerPage) + (index + 1)}</td>
                                            <td>{data.orderNumber}</td>
                                            <td>{data.TagType.name}</td>
                                            <td>{data.OrderStatus.osname}</td>
                                            <td style={{ fontSize: '10px', whiteSpace: 'nowrap' }}>{data.tbatches}</td>
                                            <td style={{ fontSize: '10px', whiteSpace: 'nowrap' }}>{getformattedDate(data.CreatedAt)}</td>
                                            <td>
                                                {true && (
                                                    <MdIcons.MdPreview
                                                        className="mx-1"
                                                        title="View Order Batches "
                                                        style={{ height: "15px", width: "15px" }}
                                                        onClick={() => {
                                                            setSelectedOrderId(data.tagid)
                                                            setSelectedOrderNumber(data.orderNumber)
                                                            setShowOrderBatches(true)
                                                        }}
                                                    />
                                                )}


                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        ) : (
                            <p>No orders.</p>
                        )}

                        {orders?.length > 0 && (
                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={orders?.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        )}

                    </div>
                </Row>
            </Container>
        );
    }

}

export default EventBatchManagement;
