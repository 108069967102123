import React, { useEffect, useRef, useState } from 'react';
import { Form, Table, Card, Col, Container, Row } from 'react-bootstrap';
import * as RiIcons from 'react-icons/ri';
import * as TbIcons from 'react-icons/tb';
import * as GiIcons from 'react-icons/gi';
import * as BsIcons from 'react-icons/bs';
import * as GrIcons from 'react-icons/gr';
import * as MdIcons from 'react-icons/md';
import * as HiIcons from 'react-icons/hi';
import * as FaIcons from 'react-icons/fa';
import * as IoIcons from 'react-icons/io';
import * as TiIcons from 'react-icons/ti';
import { ImCross } from "react-icons/im";
import { Button, Modal } from 'antd';
import useGeneralState from '../../hooks/hooks/generalState';
import { fetchData } from '../../Utilities/apiRequests';
import Loader from '../Loader';
import { twit } from '../Notificationpopout';
import axios from '../../api/axios';
import PhoneInput from 'react-phone-input-2';
import mtnLogo from '../images/mtnMomo.png';
import airtelLogo from '../images/airtelLogo.png';
import SwitchSelector from 'react-switch-selector';
import logo from '../images/logo.jpg'
import visaLogo from '../images/visaPaymentLogos.png';
import { useNavigate } from 'react-router-dom';
import { costPerCredit } from '../../Utilities/globals';
import TransparentOverlay from '../TransparentOverlay';

const BillingAccounts = () => {

    const [baccounts, setBaccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showPayment, setShowPayment] = useState(false);
    const [amount, setAmount] = useState();
    const [aid, setAid] = useState('');
    const [phoneNumberType, setPhoneNumberType] = useState('MTN');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [transactions, setTransactions] = useState([]);
    const [showPaymentOverlay, setShowPaymentOverlay] = useState(false);

    const navigate = useNavigate()
    useEffect(() => {
        fetchData(`/api/billing/accounts`, setLoading).then((response) => {

            setBaccounts(response.data.accounts)


        }).catch((error) => {
            console.log('accounts loading error:', error.response?.data?.message ? error.response?.data?.message : 'error loading accounts')
        })

    }, []);

    useEffect(() => {
        fetchData(`/api/billing/accounts/transactions`, setLoading).then((response) => {

            setTransactions(response.data.userTransactions)


        }).catch((error) => {
            console.log('transactions loading error:', error.response?.data?.message ? error.response?.data?.message : 'error loading accounts')
        })

    }, []);

    const initiateCreditPurchase = async (setLoading) => {
        const token = sessionStorage.getItem('token');
        const postData = {
            aid,
            amount,
            provider: phoneNumberType == 'MTN' ? 'MTN_UGANDA' : phoneNumberType == 'Airtel' ? 'AIRTEL_UGANDA' : ' ',
            phone: phoneNumber
        };

        const Url = '/api/billing/credits/purchase/initiate'
        try {
            //setLoading(true);
            setShowPaymentOverlay(true)
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {
                //setLoading(false);
                setShowPaymentOverlay(false)
                return Promise.resolve(response.data.message)

            } else if (response.status === 202) {
                //setLoading(false);
                setShowPaymentOverlay(false)
                return Promise.resolve(response.data.message)
            }




        } catch (err) {
            //setLoading(false);
            setShowPaymentOverlay(false)
            console.log("Credits Purchase Initiation Error:", err)
            return Promise.reject(err.response.data.message ? err.response.data.message : 'An error occured during credits purchase initiation.')
        }
    };

    const handleCreditPurchase = (e) => {
        e.preventDefault();

        if (!amount) {
            twit('info', 'Please provide the amount to deposit.')
        } else if (!phoneNumber) {
            twit('info', 'Please provide the phone number.')
        } else {
            initiateCreditPurchase(setLoading).then((data) => {
                twit('success', data)
                setAmount('')
                setPhoneNumber('')
                setShowPayment()
                navigate('/billing')
            }).catch((error) => {
                twit('info', error)
            })


        }



    };

    const inputRef = useRef(null);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef.current, showPayment])


    const options = [
        { label: 'MTN', value: 'MTN', selectedBackgroundColor: "#f7da57", },
        { label: 'Airtel', value: 'Airtel', selectedBackgroundColor: "#FF0000", },
        { label: 'Other', value: 'Visa', selectedBackgroundColor: "#0000FF", }
    ];

    const initialSelectedIndex = options.findIndex(({ value }) => value === phoneNumberType);

    if (loading) {
         return (<Loader />); 
    } else {

        return (
            <Container >
                {showPaymentOverlay&&(
                    <TransparentOverlay message={'A payment prompt has been sent, awaiting approval.'} />
                )}
                {showPayment && <Modal open={true} title={'Purchase Credits'} onCancel={() => {

                    setShowPayment(false)

                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >



                        <Form onSubmit={handleCreditPurchase}>

                            <Card>
                                <Card.Body >

                                    {/* <Card.Img variant="top" style={{ maxHeight: '350px' }} src={'https://st4.depositphotos.com/6903990/27898/i/450/depositphotos_278981062-stock-photo-beautiful-young-woman-clean-fresh.jpg'} /> */}

                                    <Row>




                                        <Col className='my-1' md={12}>
                                            <Row>
                                                <Col className='my-1' md={12}>
                                                    <div >
                                                        <img src={logo} className='payment-logo' />
                                                    </div>
                                                </Col>
                                                <Col className='my-2' md={12}>

                                                    <div style={{ height: 30 }}>
                                                        <SwitchSelector
                                                            options={options}
                                                            onChange={(value) => setPhoneNumberType(value)}
                                                            initialSelectedIndex={initialSelectedIndex}
                                                            backgroundColor={"#fefefe"}
                                                            fontColor={"#1a1a1c"}
                                                            selectedFontColor={phoneNumberType == 'MTN' ? '#000000' : '#fff'}
                                                            wrapperBorderRadius={0}
                                                            fontSize={12}
                                                            optionBorderRadius={8}

                                                        />
                                                    </div>


                                                    <div className='mt-3' style={{}} >
                                                        {phoneNumberType === "MTN" && (
                                                            <img src={mtnLogo} className='payment-card-mtn-image' />
                                                        )}
                                                        {phoneNumberType === "Airtel" && (
                                                            <img src={airtelLogo} className=' payment-card-mtn-image mt-3' />
                                                        )}
                                                        {phoneNumberType === "Visa" && (
                                                            <img src={visaLogo} className='payment-card-visa-image mt-2 mb-2' />
                                                        )}

                                                    </div>


                                                </Col>
                                                {phoneNumberType != 'Visa' && (
                                                    <Col className='my-1' md={12}>
                                                        <div className='my-2' style={{ display: "flex", flexDirection: "column", alignItems: 'center', width: '100%', paddingLeft: '10px' }}>

                                                            <p className='text-muted' style={{ margin: 0 }}>Minimum deposit is 10,000 UGX</p>
                                                            <p className='text-muted' style={{ margin: 0 }}>1 Credit = {costPerCredit} UGX</p>

                                                        </div>
                                                        {(transactions?.length == 0) && (
                                                            <div className='my-2' style={{ display: "flex", flexDirection: "column", alignItems: 'center', width: '100%', paddingLeft: '10px' }}>


                                                                <p className='text-muted animatedText' style={{ margin: 0 }}>
                                                                    <span className='rainbowText'>
                                                                        Deposit 100,000 UGX and receive 110,000 Credit on your first-time deposit
                                                                    </span>
                                                                </p>

                                                            </div>
                                                        )}


                                                        <Form.Group controlId="name">
                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>



                                                                <div className='mx-2 FormLabels'>
                                                                    <p style={{ margin: 0 }}>Amount</p>
                                                                </div>

                                                                <Form.Control
                                                                    ref={inputRef}
                                                                    className=' input-box-events form-control-sm'
                                                                    type="number"
                                                                    placeholder="Enter amount "
                                                                    value={amount}
                                                                    min={10000}
                                                                    onChange={(e) => {
                                                                        setAmount(e.target.value)
                                                                    }}

                                                                />
                                                            </div>

                                                        </Form.Group>
                                                    </Col>
                                                )}

                                                {phoneNumberType == 'Visa' && (
                                                    <div className='my-2' style={{ display: "flex", flexDirection: "column", alignItems: 'center', width: '100%', paddingLeft: '10px' }}>

                                                        <p className='text-muted' style={{ margin: 0 }}>Not available now !!</p>


                                                    </div>
                                                )}

                                                {phoneNumberType != 'Visa' && (
                                                    <Col className='my-1' md={12}>
                                                        <Form.Group controlId="name">
                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>



                                                                <div className='mx-2 FormLabels'>
                                                                    <p style={{ margin: 0 }}>Phone</p>
                                                                </div>

                                                                <PhoneInput
                                                                    country={'ug'}
                                                                    value={phoneNumber}
                                                                    localization={{ ug: 'Uganda' }}
                                                                    onChange={(e) => {

                                                                        setPhoneNumber(e);
                                                                    }}

                                                                    disableDropdown
                                                                    countryCodeEditable={false}
                                                                    masks={{ ug: '... ... ...' }}



                                                                    inputProps={{
                                                                        name: 'phone',
                                                                        required: true,

                                                                    }}


                                                                    inputStyle={{
                                                                        width: "100%",
                                                                        height: "30px",
                                                                        fontSize: "12px"
                                                                    }}

                                                                />
                                                            </div>

                                                        </Form.Group>
                                                    </Col>
                                                )}



                                            </Row>
                                        </Col>




                                    </Row>
                                    {phoneNumberType != 'Visa'&&(
                                        <div className='my-2' style={{ display: "flex", flexDirection: "column", alignItems: 'center', width: '100%', paddingLeft: '10px' }}>

                                        <p className='text-muted' style={{ margin: 0 }}>All payments are made through YO UGANDA LIMITED.</p>


                                    </div>
                                    )}
                                    
                                    <div className='my-2' style={{ display: "flex", flexDirection: "column", alignItems: 'center', width: '100%', paddingLeft: '10px' }}>

                                        <p className='text-muted' style={{ margin: 0 }}><MdIcons.MdSecurity className='mx-1' style={{ width: '15px', height: '15px' }} />Payments are secure and encrypted.</p>


                                    </div>

                                </Card.Body>
                            </Card>



                            {phoneNumberType != 'Visa' && (
                                <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                    <Button disabled={phoneNumberType == 'Visa'} title={phoneNumberType == 'Visa' ? 'Coming soon...' : 'Purchase credits'} style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit' >
                                        <GiIcons.GiPayMoney className='mx-1' style={{ width: '15px', height: '15px' }} /> | Pay now
                                    </Button>
                                </div>
                            )}


                        </Form>



                    </div>


                </Modal>}
                <Row >
                    <Col className='my-1' md={6}>
                        <div className='mt-0' style={{ width: '100%', textAlign: 'start' }}>
                            <Button disabled style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary">
                                <GiIcons.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Create New Account
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row >
                    <Col className='my-3' md={12}>
                        <p className='my-0' style={{ fontWeight: 'bold' }}> Your Billing Accounts </p>
                        <div className='table-responsive'>
                            {baccounts?.length > 0 ?
                                <Table hover style={{ cursor: "pointer" }}>
                                    <thead>
                                        <tr>
                                            <th>Billing account name</th>
                                            <th style={{ textAlign: "center" }}>Billing account ID</th>
                                            <th>Status</th>
                                            {/* <th># of credits</th>
                                            <th># of projects</th> */}
                                            <th>Operations</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {baccounts?.map((data, index) => (
                                            <tr key={index} onClick={() => { }}>
                                                <td>{data.name}</td>
                                                <td style={{ textAlign: "center" }}>{data.externalAccountNo}</td>
                                                <td>{!data.astatus ? <ImCross title='Inactive' style={{ height: "15px", width: "15px", color: 'red' }} /> : <TiIcons.TiTick title='Active' style={{ height: "20px", width: "20px", color: 'green' }} />}</td>
                                                {/* <td>{data.credits}</td>
                                                <td>{data.projects}</td> */}
                                                <td>
                                                    <IoIcons.IoIosAddCircleOutline className='mx-1' title='Buy More Credits' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                        setAid(data.aid)
                                                        setShowPayment(true)
                                                    }} />
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                :

                                <p>No accounts loaded.</p>

                            }

                        </div>
                        

                    </Col>

                </Row>
            </Container>
        )
    }
}

export default BillingAccounts
