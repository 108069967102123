export const cardTypeNames = {
    bronze_Card:'PinniSEED',
    silver_Card:'PinniSPROUT',
    gold_Card:'PinniBUD',
    diamond_Card:'PinniBlossom', 
    platinum_Card:'PinniPOLLEN'

}

export const verifyEngineBaseUrl ='https://c.pinnitags.com'
export const clientEngineBaseUrl ='https://c.pinnitags.com'
// export const fileUploadBaseUrl = 'https://api.pinnitags.com'
export const fileUploadBaseUrl = 'https://pinnitagsapiv1.westus.cloudapp.azure.com'
// export const apiBaseUrl = 'api.pinnitags.com'
// export const apiBaseUrl = 'http://13.64.231.198:80'
export const apiBaseUrl = 'https://pinnitagsapiv1.westus.cloudapp.azure.com'
export const pinnitagsLandingUrl = 'https://pinnitags.com/'
export const pinnitagsCookiePolicy = 'https://pinnitags.com/Cookie%20Policy.html'
export const pinnitagsTermsConditions = 'https://pinnitags.com/TermsAndConditions.html'
export const pinnitagsPrivacyPolicy = 'https://pinnitags.com/PrivacyPolicy.html'
export const pinnitagsOTPpolicy = 'https://pinnitags.com/otpPolicy.html'
export const costPerCredit = 1.02
export const pinnisoftUrl = 'https://www.pinnisoft.com/'
export const maxImageFileSize = 2
export const comissionPercentage = (7/100)
export const innerTimeOutSeconds = 300
