import { useEffect, useRef, useState } from "react";
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from "react-router-dom";

const useInactivitySignOut = (onSignOut,timeOutSeconds,warningSeconds) => {
    const [isActive, setIsActive] = useState(true);
    const timeoutRef = useRef(null);
    const [showWarning, setShowWarning] = useState(false);
    const loggedin = Cookies.get('loggedin');
    const warningTimeoutRef = useRef(null);
    const [secondsLeft, setSecondsLeft] = useState(warningSeconds);
    const timerIdRef = useRef(null);


    useEffect(() => {
        if (loggedin) {
            timeoutRef.current = setTimeout(() => {
                setIsActive(false);
                setShowWarning(true); // Show warning 30 seconds before signout


                timerIdRef.current = setInterval(() => {
                  setSecondsLeft(prevSeconds => prevSeconds > 0 ? prevSeconds - 1 : 0);
                }, 1000);

                 warningTimeoutRef.current = setTimeout(() => {
                    onSignOut();
                }, warningSeconds * 1000); // Sign out after additional 30 seconds

                // Cleanup warning timeout on component unmount or signout
                return () => {
                  clearInterval(timerIdRef.current);
                  timerIdRef.current = null;
                  clearTimeout(warningTimeoutRef.current)};
                
            }, timeOutSeconds * 1000);
        }


        return () => clearTimeout(timeoutRef.current);
    }, [loggedin]);

    const handleActivity = () => {

        setIsActive(true);
        clearTimeout(timeoutRef.current);
        setShowWarning(false); // Reset warning if user interacts
        clearTimeout(warningTimeoutRef.current);
        clearInterval(timerIdRef.current);
        timerIdRef.current = null;
        setSecondsLeft(warningSeconds);


        // Reset timeout for next inactivity period
        timeoutRef.current = setTimeout(() => {
            setIsActive(false);
            setShowWarning(true); // Show warning 30 seconds before signout


            timerIdRef.current = setInterval(() => {
              setSecondsLeft(prevSeconds => prevSeconds > 0 ? prevSeconds - 1 : 0);
            }, 1000);

             warningTimeoutRef.current = setTimeout(() => {
                onSignOut();
            }, warningSeconds * 1000); // Sign out after additional  seconds

            // Cleanup warning timeout on component unmount or signout
            return () => {
              clearInterval(timerIdRef.current);
              timerIdRef.current = null;
              clearTimeout(warningTimeoutRef.current)};
        }, timeOutSeconds * 1000); 
    };

    useEffect(() => {

        if (loggedin) {
            const events = ['click', 'mousemove', 'keydown', 'touchstart','scroll'];
            events.forEach((event) => window.addEventListener(event, handleActivity));

            return () => {
                events.forEach((event) => window.removeEventListener(event, handleActivity));
                clearTimeout(timeoutRef.current);
            };
        }



    }, [loggedin]);

    return { isActive, showWarning,secondsLeft };
}



export const useCountdown = (initialDuration) => {
  const [secondsLeft, setSecondsLeft] = useState(initialDuration);
  const timerIdRef = useRef(null);

  const startCountdown = () => {
    if (timerIdRef.current) return; // Prevent starting multiple timers

    timerIdRef.current = setInterval(() => {
      setSecondsLeft(prevSeconds => prevSeconds > 0 ? prevSeconds - 1 : 0);
    }, 1000);
  };

  const clearCountdown = () => {
    clearInterval(timerIdRef.current);
    timerIdRef.current = null;
    setSecondsLeft(initialDuration);
  };

  useEffect(() => {
    // Cleanup function to clear the interval when the component unmounts
    return () => clearCountdown();
  }, []);

  return { secondsLeft, startCountdown, clearCountdown };
};

export const useInactivitySignOutColl = (loggedinColl, CollAccessTimeOutSecs) => {
  
  
 
  const loggedin = Cookies.get('loggedin');
  const collectionsTimeoutRef = useRef(null);
  const path = useLocation().pathname
  const navigate = useNavigate();
  const onCollectionsPath = path.includes("/settings/collections")||path.includes("/collections")
  
  

  const OnCollectionTimeOut = () => {
    if(loggedinColl){
    console.log('removed access')
    Cookies.remove('CollAccess');
    if(onCollectionsPath)(
      navigate('/')
    )
    }
    
  }

 

 

  useEffect(() => {
    
    if (loggedinColl) {
      //console.log('added initial timeout')
      collectionsTimeoutRef.current = setTimeout(() => {

        OnCollectionTimeOut()

      }, CollAccessTimeOutSecs * 1000);
    }

    return () =>{
      //console.log('removed initial timeout')
      clearTimeout(collectionsTimeoutRef.current);
    } 
  }, [loggedinColl]);
  const handleActivityCollectionAccess = () => {

    //console.log('activity function called.')

    clearTimeout(collectionsTimeoutRef.current);


    // Reset timeout for next inactivity period
    collectionsTimeoutRef.current = setTimeout(() => {

      OnCollectionTimeOut()

    }, CollAccessTimeOutSecs * 1000);
  };

  useEffect(() => {

    if (loggedin) {
      const events = ['click', 'mousemove', 'keydown', 'touchstart', 'scroll'];
      

      if (loggedinColl && onCollectionsPath) {
        //console.log('added listeners')
        events.forEach((event) => window.addEventListener(event, handleActivityCollectionAccess));
      }

      return () => {
        //console.log('removed listeners')
        events.forEach((event) => window.removeEventListener(event, handleActivityCollectionAccess));
        clearTimeout(collectionsTimeoutRef.current);
        Cookies.remove('CollAccess');
      };
    }



  }, [loggedin,loggedinColl,onCollectionsPath]);

  return { };
}


export default useInactivitySignOut