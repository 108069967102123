import React, { useEffect, useState } from 'react';
import { Form, Table, Card, Col, Container, Nav, Row } from 'react-bootstrap';
import * as RiIcons from 'react-icons/ri';
import * as TbIcons from 'react-icons/tb';
import * as GiIcons from 'react-icons/gi';
import * as BsIcons from 'react-icons/bs';
import * as GrIcons from 'react-icons/gr';
import * as MdIcons from 'react-icons/md';
import * as HiIcons from 'react-icons/hi';
import * as FaIcons from 'react-icons/fa';
import * as CgIcons from 'react-icons/cg';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, Rectangle, PieChart, Pie, Cell, } from 'recharts';
import useGeneralState from '../../hooks/hooks/generalState';
import { fetchData } from '../../Utilities/apiRequests';
import { formatPrice, getNairobiTime, getformattedDate, isMTNAirtel } from '../../Utilities/utilities';
import Loader from '../Loader';
import Pagination from '../Pagination';
import { costPerCredit } from '../../Utilities/globals';



const BillingOverview = () => {





    const [pcredits, setPcredits] = useState();
    const [fcredits, setFcredits] = useState();
    const [totalpcredits, setTotalpcredits] = useState();
    const [baccounts, setBaccounts] = useState([]);
    const [activeAccount, setActiveAccount] = useState();
    const [loading, setLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);




    useEffect(() => {
        fetchData(`/api/billing/accounts`, setLoading).then((response) => {

            setBaccounts(response.data.accounts)
            setActiveAccount(response.data.accounts.filter((data) => {
                return data.name == 'Primary'
            })[0]?.aid)

        }).catch((error) => {
            console.log('accounts loading error:', error.response?.data?.message ? error.response?.data?.message : 'error loading accounts')
        })

    }, []);

    useEffect(() => {
        fetchData(`/api/billing/accounts/transactions`, setLoading).then((response) => {

            setTransactions(response.data.userTransactions)


        }).catch((error) => {
            console.log('transactions loading error:', error.response?.data?.message ? error.response?.data?.message : 'error loading accounts')
        })

    }, []);




    useEffect(() => {


        if (baccounts?.length > 0) {
            fetchData(`/api/billing/credits/${activeAccount}`, setLoading).then((response) => {
                setPcredits(response.data.premiumCredits)
                setFcredits(response.data.freeCredits)
            }).catch((error) => {
                console.log('accounts loading error:', error.response?.data?.message ? error.response?.data?.message : 'error loading accounts')
            })
        }


    }, [activeAccount]);


    useEffect(() => {


        if (activeAccount) {
            fetchData(`/api/billing/accounts/credits/${activeAccount}`, setLoading).then((response) => {
                
                let creditsTotal = 0
            for(const credit of response.data.credits){
                creditsTotal += credit.credit
            }

            setTotalpcredits(creditsTotal)
       
            }).catch((error) => {
                console.log('accounts loading error:', error.response?.data?.message ? error.response?.data?.message : 'error loading accounts')
            })
        }


    }, [activeAccount]);





    const data01 = [
        {
            "name": "Remaining Credits",
            "value": pcredits ? pcredits?.balance : 0,
            "color": 'gold'
        },
        {
            "name": "Used Credits",
            "value": pcredits && totalpcredits ? (Number(totalpcredits).toFixed(2)-Number(pcredits?.balance).toFixed(2)) : 0,
            "color": 'gray'
        },

    ];

    const data02 = [
        {
            "name": "Remaining Credits",
            "value": fcredits ? fcredits?.balance : 0,
            "color": '#82ca9d'
        },
        {
            "name": "Used Credits",
            "value": fcredits ? (10000 - fcredits?.balance) : 0,
            "color": 'gray'
        },

    ];


    const timeDifference = new Date(fcredits?.expires) - new Date(getNairobiTime());

    // Calculate the difference in days
    const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    //pagination logic
    const postsPerPage = 5
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginatedTransactions = transactions?.slice(indexOfFirstPost, indexOfLastPost)
    const paginate = (number) => {
        setCurrentPage(number);

    }

    if (loading) {
        return (<Loader />);
    } else {

        return (
            <Container>
                <Row>
                    <Col className='my-3' md={6}>
                        <Form.Group controlId="passColor">
                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                                <Form.Select
                                    disabled
                                    name="eventPassColor"
                                    onChange={(e) => {

                                    }}
                                    className='form-selector'>

                                    <option className='input-box' key={1} value={''}>{'Primary'}</option>
                                    {baccounts.map((data) => {
                                        return <option className='input-box' key={data?.aid} value={data?.aid}>{data?.name}</option>
                                    })}


                                </Form.Select>
                            </div>

                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className='my-1' md={6} >
                        <Card style={{ minHeight: '100%' }}>
                            <Card.Header>
                                Free Credits
                            </Card.Header>
                            <Card.Body>

                                <div className='credits-overview'>
                                    <div className='credits-pie'>

                                        <PieChart width={180} height={100}>
                                            <Pie
                                                data={data02}
                                                dataKey="value"
                                                nameKey="name"
                                                cx="25%"
                                                cy="50%"
                                                innerRadius={30}
                                                outerRadius={40}
                                                fill="#8884d8" // Default color for the entire Pie

                                            >
                                                {data02.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={entry.color} />
                                                ))}
                                            </Pie>
                                            <Tooltip />
                                        </PieChart>

                                    </div>
                                    <div >

                                        <Row >
                                            <Col style={{ minHeight: '50%', }} className='credits-value' md={12}>
                                                <h4 className='text-muted my-0'>{fcredits ? formatPrice(fcredits?.balance) : 0}</h4>
                                                <p className=' my-0'>Credits remaining</p>
                                            </Col>
                                            <Col className='' md={12}>
                                                <p className=' my-0'>Out Of 10,000</p>
                                                <p className=' my-0 text-muted'>{dayDifference < 0 ? 'Expired' :dayDifference? `Expiring in ${dayDifference} days`:'Expiring in 0 days'}</p>

                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='my-1' md={6} >
                        <Card style={{ minHeight: '100%' }}>
                            <Card.Header>
                                Premium Credits
                            </Card.Header>
                            <Card.Body>

                                <div className='credits-overview'>
                                    <div className='credits-pie'>

                                        <PieChart width={180} height={100}>
                                            <Pie
                                                data={data01}
                                                dataKey="value"
                                                nameKey="name"
                                                cx="25%"
                                                cy="50%"
                                                innerRadius={30}
                                                outerRadius={40}
                                                fill="#8884d8" // Default color for the entire Pie

                                            >
                                                {data01.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={entry.color} />
                                                ))}
                                            </Pie>
                                            <Tooltip />
                                        </PieChart>

                                    </div>
                                    <div className='credits-details'>

                                        <Row >
                                            <Col style={{ minHeight: '50%', }} className='credits-value' md={12}>
                                                <h4 className='text-muted my-0'>{pcredits ? formatPrice(pcredits?.balance) : 0}</h4>
                                                <p className=' my-0'>Credits remaining</p>
                                            </Col>
                                            <Col className='' md={12}>
                                                <p className=' my-0'>Out of all purchased credits.</p>
                                                <p className=' my-0 text-muted'>Credits are valid for a lifetime</p>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <p className='mb-0 mt-3' style={{ fontWeight: 'bold' }}> Credit Deposit Transaction Log </p>
                    <div className='table-responsive'>
                        {paginatedTransactions?.length > 0 ?
                            <Table striped hover style={{ cursor: "pointer" }}>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th style={{ textAlign: "right" }}>Credits</th>
                                        <th style={{ textAlign: "right" }}>Amount{'('}UGX{')'}</th>
                                        <th>Date</th>
                                        <th>Phone Number</th>
                                        <th>Carrier</th>
                                        <th>Status</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedTransactions?.map((data, index) => (
                                        <tr key={index} onClick={() => { }}>
                                            <td>{((currentPage * 5) - postsPerPage) + (index + 1)}</td>
                                            <td style={{ textAlign: "right" }}>{formatPrice(data.credits)}</td>
                                            <td style={{ textAlign: "right" }}>{formatPrice(data.amount)}</td>
                                            <td style={{fontSize:'10px',whiteSpace:'nowrap'}}>{getformattedDate(data.dateCreated)}</td>
                                            <td>{data.phone}</td>
                                            <td>{isMTNAirtel(data.phone)}</td>
                                            <td style={{ color: data.status == 'SUCCEEDED' ? 'green' : data.status == 'FAILED' ? 'red' : '#FFBF00' }}>{data.status}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            :

                            <p>No transactions.</p>

                        }

                        {transactions?.length > 0 && (
                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={transactions?.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        )}

                    </div>
                    <div className='my-2' style={{ display: "flex", flexDirection: "column", alignItems: 'center', width: '100%', paddingLeft: '10px' }}>

                            <p className='text-muted' style={{ margin: 0 }}>1 Credit = {costPerCredit} UGX</p>

                        </div>
                </Row>
                {/* <Row>
                <Col className='my-3' md={12}>
                    <p className='my-0' style={{ fontWeight: 'bold' }}> Projects Connected To This Billing Account </p>
                    <div className='table-responsive'>
                        {[1].length > 0 ?
                            <Table  hover style={{ cursor: "pointer" }}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Project ID</th>
                                    
                                    </tr>
                                </thead>
                                <tbody>
                                    {[{name:'eventX',id:'xccxgs-133337-hdhhhhh-32442'},{name:'eventY',id:'xreteys-133337-hdhhhhh-326732'}]?.map((data, index) => (
                                        <tr key={index} onClick={() => { }}>
                                            <td>{data.name}</td>
                                            <td>{data.id}</td>
                                            
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            :

                            <p>No projects connected.</p>

                        }

                    </div>


                </Col>

            </Row> */}
            </Container>
        )
    }
}

export default BillingOverview
